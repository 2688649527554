import { lazy } from 'react'
import { TypesUser } from '../store/application/models/users/users'

const ListAdmins = lazy(() => import('../containers/users/admin/list'))
const ProfileAdmin = lazy(() => import('../containers/users/admin/profile'))
const ListHealthProfessional = lazy(() => import('../containers/users/health.professional/list'))
const ProfileHealthProfessional = lazy(() => import('../containers/users/health.professional/profile'))
const ListAssociates = lazy(() => import('../containers/users/health.professional/list.associates'))
const ListCaregiver = lazy(() => import('../containers/users/caregiver/list'))
const ProfileCaregiver = lazy(() => import('../containers/users/caregiver/profile'))
const ViewCaregiver = lazy(() => import('../containers/users/caregiver/view'))

const UserProfile = lazy(() => import('../containers/users/profile'))

const hasQuestApp: boolean = Boolean(process.env.REACT_APP_QUEST_APP || '')

export const USERS_ROUTES = [
    {
        path: '/app/myprofile',
        component: UserProfile,
        exact: true,
        private: true
    },
    {
        path: '/app/admins',
        component: ListAdmins,
        exact: true,
        private: true,
        scopes: ['ad:ra']
    },
    {
        path: '/app/admins/:userId',
        component: ProfileAdmin,
        exact: true,
        private: true,
        scopes: ['ad:r']
    },
    {
        path: '/app/healthprofessionals',
        component: ListHealthProfessional,
        exact: true,
        private: true,
        scopes: ['hp:ra']
    },
    {
        path: '/app/healthprofessionals/:userId',
        component: ProfileHealthProfessional,
        exact: true,
        private: true,
        scopes: ['hp:r']
    },
    {
        path: '/app/healthprofessionals/:userId/associates',
        component: ListAssociates,
        exact: true,
        private: true,
        scopes: ['ca:r', 'pa:r']
    },
    {
        path: '/app/caregivers',
        component: ListCaregiver,
        exact: true,
        private: true,
        scopes: ['ca:r'],
        userTypes: [
            TypesUser.ADMIN,
            TypesUser.HEALTH_PROFESSIONAL
        ],
        blocked: hasQuestApp
    },
    {
        path: '/app/caregivers/:userId',
        component: ProfileCaregiver,
        exact: true,
        private: true,
        scopes: ['ca:r'],
        blocked: hasQuestApp
    },
    {
        path: '/app/caregivers/:userId/patients',
        component: ViewCaregiver,
        exact: true,
        private: true,
        scopes: ['ca:r', 'pa:r'],
        blocked: hasQuestApp
    },
    {
        path: '/app/caregivers/:userId/patients',
        component: ViewCaregiver,
        exact: true,
        private: true,
        scopes: ['ca:r', 'pa:r'],
        blocked: hasQuestApp
    }
]
