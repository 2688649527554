import Address from './address'
import ExternalService from './external.service'
import Goal from './goals'
import Sociodemographic from './sociodemographic'
import User, { TypesUser } from './users'
import Fatigue from './fatigue'
import DurationTransform from '../../../../components/sleep/duration.transform'
import QuestionnaireStatus from '../questionnaires/questionnaire.status'

export default class Patient extends User {
    // Required
    private _address: Address | undefined
    private _sociodemographic: Sociodemographic | undefined
    // Readonly
    private _goals: Goal | undefined
    private _external_services: ExternalService | undefined
    private _fatigue: Fatigue | undefined
    private _weight: number | undefined
    private _height: number | undefined
    private _health_professional_id: string | undefined
    private _last_heart_rate: string | undefined
    private _questionnaire_status: QuestionnaireStatus | undefined
    private _sigop_registration: number | undefined

    constructor() {
        super()
        this.type = TypesUser.PATIENT
    }

    get address(): Address | undefined {
        return this._address
    }

    set address(value: Address | undefined) {
        this._address = value
    }

    get sociodemographic(): Sociodemographic | undefined {
        return this._sociodemographic
    }

    set sociodemographic(value: Sociodemographic | undefined) {
        this._sociodemographic = value
    }

    get goals(): Goal | undefined {
        return this._goals
    }

    set goals(value: Goal | undefined) {
        this._goals = value
    }

    get external_services(): ExternalService | undefined {
        return this._external_services
    }

    set external_services(value: ExternalService | undefined) {
        this._external_services = value
    }

    get weight(): number | undefined {
        return this._weight
    }

    set weight(value: number | undefined) {
        this._weight = value
    }

    get height(): number | undefined {
        return this._height
    }

    set height(value: number | undefined) {
        this._height = value === undefined || value < 3 ? value : (
            value >= 100 ?
                value / 100
                : value / 10
        )
    }

    get health_professional_id(): string | undefined {
        return this._health_professional_id
    }

    set health_professional_id(value: string | undefined) {
        this._health_professional_id = value
    }

    get fatigue(): Fatigue | undefined {
        return this._fatigue
    }

    set fatigue(value: Fatigue | undefined) {
        this._fatigue = value
    }

    get last_heart_rate(): string | undefined {
        return this._last_heart_rate
    }

    set last_heart_rate(value: string | undefined) {
        this._last_heart_rate = value
    }

    get questionnaire_status(): QuestionnaireStatus | undefined {
        return this._questionnaire_status
    }

    set questionnaire_status(value: QuestionnaireStatus | undefined) {
        this._questionnaire_status = value
    }

    get sigop_registration(): number | undefined {
        return this._sigop_registration
    }

    set sigop_registration(value: number | undefined) {
        this._sigop_registration = value
    }

    public fromJSON(json: any): Patient {
        super.fromJSON(json)

        if (json.address !== undefined) {
            this.address = new Address().fromJSON(json.address)
        }

        if (json.sociodemographic !== undefined) {
            this.sociodemographic = new Sociodemographic().fromJSON(json.sociodemographic)
        }

        if (json.goals !== undefined) {
            this.goals = new Goal().fromJSON(json.goals)
        }

        if (json.external_services !== undefined) {
            this.external_services = new ExternalService().fromJSON(json.external_services)
        }

        if (json.fatigue !== undefined) {
            this.fatigue = new Fatigue().fromJSON(json.fatigue)
        }

        if (json.weight !== undefined) {
            this.weight = json.weight
        }

        if (json.height !== undefined) {
            this.height = json.height
        }

        if (json.health_professional_id !== undefined) {
            this.health_professional_id = json.health_professional_id
        }

        if (json.last_heart_rate !== undefined) {
            this.last_heart_rate = json.last_heart_rate
        }

        if (json.questionnaire_status !== undefined) {
            this.questionnaire_status = new QuestionnaireStatus().fromJSON(json.questionnaire_status)
        }

        if (json.sigop_registration !== undefined) {
            this.sigop_registration = parseInt(json.sigop_registration, 10)
        }

        return this
    }

    public toJSON(): any {
        const height: number | undefined = this?.height && this.height < 3 ? this.height * 100 : this.height
        return {
            ...super.toJSON(),
            address: this.address ? this.address.toJSON() : undefined,
            sociodemographic: this.sociodemographic ? this.sociodemographic.toJSON() : undefined,
            goals: this.goals ? this.goals.toJSON() : undefined,
            fatigue: this.fatigue ? this.fatigue.toJSON() : undefined,
            external_services: this.external_services ? this.external_services.toJSON() : undefined,
            weight: this.weight ? this.weight : undefined,
            height,
            health_professional_id: this.health_professional_id ? this.health_professional_id : undefined,
            last_heart_rate: this.last_heart_rate ? this.last_heart_rate : undefined,
            sigop_registration: this.sigop_registration ? this.sigop_registration : undefined
        }
    }

    public age() {
        const now = new Date()
        const diff = Math.abs((this.birth_date?.getTime() || 0) - now.getTime())
        return Math.floor(DurationTransform.getYearsTruncate(diff))
    }

    public bmi(): number {
        const height: number = this.heightToMeters()
        const value = (this?.weight || 0) / Math.pow(height, 2)
        return parseFloat(value.toFixed(2))
    }

    public heightToMeters(): number {
        const height: number = this.height || 0
        if (height > 3) {
            return height * 0.01
        }
        return height
    }
}
