export enum ThemeMode {
    LIGHT = 'light',
    DARK = 'dark'
}

export const MAP_ENUM_THEME = {
    light: ThemeMode.LIGHT,
    dark: ThemeMode.DARK
}

const DEFAULT_THEME: any = {
    spacing: 10,
    palette: {
        primary: { main: '#2e72e0' },
        secondary: { main: '#003770' },
        accent: { main: '#AAD05E' },

        error: { main: '#A94442' },
        warning: { main: '#FF7043' },
        info: { main: '#165C73' },
        success: { main: '#50BB98' },

        white: { main: '#FFFFFF' },
        green: { main: '#4CB050' },

        progress: { main: '#63D1AD' },
        progressAccent: { main: '#B2FF59' },

        sleepProgress: { main: '#EDE7F6', dark: '#432970' },
        sleepProgressAccent: { main: '#9575CD' },

        stageDeep: { main: '#432970' },
        stageRem: { main: '#665189' },
        stageLight: { main: '#796798' },
        stageAwake: { main: '#F9A825' },

        fitbitActive: { main: '#00B0B9' },
        fitbitInactive: { main: '#BDBDBD' },

        activityGroupOne: { main: '#165C73' },
        activityGroupTwo: { main: '#891262' },
        activityGroupThree: { main: '#FF7043' },

        chartLine: { main: '#EAF7F3', dark: '#27966A' },
        chartBar: { main: '#B3E5D6' },
        chartBarSecondary: { main: '#9FBFCB' },

        vfc: { main: '#E75555' },
        velocity: { main: '#0AA1F5' },
        distance: { main: '#5FCCCB' },
        activeMinutes: { main: '#AFE42C' },
        calories: { main: '#F9A825' },
        steps: { main: '#00A594' },
        sleep: { main: '#2B4A77' },

        weight: { main: '#50BB98' },
        height: { main: '#AFE42C' },
        calfCircumference: { main: '#8DC44E' },
        bloodPressure: { main: '#E75555' },
        bloodGlucose: { main: '#9575CD' },
        handGrip: { main: '#1C244D' },
        bodyFat: { main: '#FF7043' },
        bodyTemperature: { main: '#4CB050' },
        waistCircumference: { main: '#63D1AD' },
        oxygenSaturation: { main: '#165c73' },

        fatBurn: { main: '#FEB31A' },
        cardio: { main: '#FF8D19' },
        peak: { main: '#E60013' },
        outOfRange: { main: '#4EC5C5' }
    },
    typography: {
        fontFamily: 'Roboto'
    }
}

const DARK_THEME: any = {
    ...DEFAULT_THEME,
    palette: {
        ...DEFAULT_THEME.palette,

        type: 'dark',

        backgroundColor: { main: '#212121' },
        backgroundCard: { main: '#333333' },
        lineDivider: { main: '#F3E7CF' },

        secondary: { main: '#2d6c81' },

        sleepProgress: { main: '#EDE7F6', dark: '#8f62de' },

        text: {
            primary: '#FFFFFF'
        }
    }
}

const LIGHT_THEME: any = {
    ...DEFAULT_THEME,
    palette: {
        ...DEFAULT_THEME.palette,

        type: 'light',

        backgroundColor: { main: '#F0F0F0' },
        backgroundCard: { main: '#FFFFFF' },
        lineDivider: { main: '#E8E8E8' },

        text: {
            primary: '#666666'
        }
    }
}

const CONFIG_THEME = {
    light: LIGHT_THEME,
    dark: DARK_THEME
}

export interface IApplicationConfig {
    readonly abbreviation: string
    readonly phone: string
    readonly email: string
    readonly website: string
    readonly report: {
        pt: { title: string },
        en: { title: string },
    }
}

export const APPLICATION_CONFIG: IApplicationConfig = {
    abbreviation: 'SS',
    phone: '556120170073',
    email: 'faleconosco@sestsenat.org.br',
    website: 'https://www.sestsenat.org.br',
    report: {
        pt: { title: 'Projeto Saúde no Transporte' },
        en: { title: 'Health in Transport Project' }
    }
}

export const ANIMATION = {
    '@keyframes fadeIn': {
        'from': {
            opacity: '0',
            transform: 'translate(0, -15px)'
        },
        'to': {
            opacity: '1',
            transform: 'translate(0, 0)'
        }
    },
    '@keyframes fadeInContent': {
        'from': {
            opacity: '0'
        },
        'to': {
            opacity: '1'
        }
    },
    fadeIn1: {
        animation: `$fadeIn 1s`
    },
    fadeIn2: {
        animation: `$fadeIn 2s`
    },
    fadeIn3: {
        animation: `$fadeIn 3s`
    },
    fadeInContent: {
        animation: `$fadeInContent 1.7s`
    }
}

export default CONFIG_THEME
